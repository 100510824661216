@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300&display=swap'); */

@font-face {
    font-family: 'Roc Grotesk';
    src: url('https://use.typekit.net/af/97dd77/00000000000000007735b7d4/30/l?subset_id=2&fvd=n4&v=3') format('woff2'),
         url('https://use.typekit.net/af/97dd77/00000000000000007735b7d4/30/d?subset_id=2&fvd=n4&v=3') format('woff'),
         url('https://use.typekit.net/af/97dd77/00000000000000007735b7d4/30/a?subset_id=2&fvd=n4&v=3') format('opentype');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    font-display: auto;
}

@font-face {
    font-family: 'Roc Grotesk';
    src: url('https://use.typekit.net/af/975d46/00000000000000007735b7c3/30/l?subset_id=2&fvd=n7&v=3') format('woff2'),
         url('https://use.typekit.net/af/975d46/00000000000000007735b7c3/30/d?subset_id=2&fvd=n7&v=3') format('woff'),
         url('https://use.typekit.net/af/975d46/00000000000000007735b7c3/30/a?subset_id=2&fvd=n7&v=3') format('opentype');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    font-display: auto;
}
.custom-filter {
    position: absolute;
    margin-top: 19rem; /* Default margin */
    margin-left: 3rem; /* Default margin */
}

.custom-button {
    padding: 4px 8px; /* Default padding */
    font-size: 0.7rem; /* Default font size */
    line-height: 1; /* Default line height */
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) { /* Adjust this value as needed for your breakpoints */
    .custom-filter {
        position: absolute;
        margin-top: 25rem; /* Adjust top margin for smaller screens */
        margin-left: 3rem; /* Reduce left margin for smaller screens */
    }

    .custom-button {
        padding: 2px 6px; /* Adjust padding for smaller screens */
        font-size: 0.6rem; /* Make font size smaller for mobile */
    }
}

@media (max-width: 480px) { /* Further adjustments for very small screens */
    .custom-filter {
        position: absolute;
        margin-top: 25rem; /* Further adjust top margin */
        margin-left: 1rem; /* Further reduce left margin */
    }

    .custom-button {
        padding: 2px 4px; /* Smaller padding for very small screens */
        font-size: 0.5rem; /* Further reduce font size */
    }
}
@media (min-width: 768px) and (max-width: 1024px) { /* Targeting iPad Air and similar tablets */
    .custom-filter {
        margin-top: 20rem; /* Adjust margin specifically for iPad */
        margin-left: 5rem; /* Adjust left margin specifically for iPad */
    }

    .custom-button {
        padding: 3px 7px; /* Adjust padding for iPad */
        font-size: 0.65rem; /* Slightly smaller font size for iPad */
    }
}

.custom-select .MuiSelect-select {
    padding: 0.6rem; /* Remove padding from the input */
    min-height: 30px; /* Set a minimum height if needed */
    
}
